import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../AuthContextProvider";
import routes from "../../navigation/routes";
import { Redirect } from "@reach/router";


/**
 * Permit only logged in users in protected / private routes.
 * Navigating to a protected route redirects to the login page.
 * @see https://www.gatsbyjs.org/tutorial/authentication-tutorial/
 */
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const authContext = useContext(AuthContext);

  if (!authContext.isLoggedIn() && location.pathname !== routes.login.to) {
    authContext.setRedirectTo(location.pathname);
    return <Redirect noThrow from={location.pathname} to={routes.login.to} />;
  }

  if (authContext.getRedirectTo() !== null) authContext.setRedirectTo(null);
  return <Component {...rest} />;
};
PrivateRoute.defaultProps = {
  location: undefined,
};
PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default PrivateRoute;
