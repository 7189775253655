import React from "react";
import { Router } from "@reach/router";
import Layout from "../../../components/element/layout/Layout";
import PageBanner from "../../../components/element/page-element/PageBanner";
import LayoutMain from "../../../components/element/layout/LayoutMain";
import SEO from "../../../components/element/SEO";
import PrivateRoute from "../../../components/auth/util/PrivateRoute";
import routes from "../../../components/navigation/routes";
import NewsletterBanner from "../../../components/navigation/footer/NewsletterBanner";
import { gettingStartedSteps } from "../../../components/user/GettingStarted";
import DeployWebsite from "../../../components/user/deploy/DeployWebsite";
import { oo } from "../../../components/element/theme";

const seo = { title: `${gettingStartedSteps[1][1]} - Oberion` };
const newsletterMessage = "🚧 Hey, this isn't quite ready yet. Get notified when this is done.";

const Deploy = () => {
  return (
    <Layout showNewsletter>
      <SEO {...seo} />
      <PageBanner heading={gettingStartedSteps[1][1]}
                  padding={`${oo.verticalSpacing * 1.25}rem 0.5rem`} />
      <NewsletterBanner path={routes.user.sites.deploy.to}
                        message={newsletterMessage}
                        padding="1.5rem 0.5rem"
                        extraButton />
      <LayoutMain>

        <Router>
          <PrivateRoute path={routes.user.sites.deploy.to} component={DeployWebsite} />
        </Router>

      </LayoutMain>
    </Layout>
  );
};
export default Deploy;
