import React from "react";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import oberionTheme from "../theme";

const useStyles = makeStyles({
  root: {
    width: props => props.width,
    padding: props => props.padding,
    margin: props => props.margin,
    backgroundColor: props => props.backgroundColor,
    color: props => props.color,
  },
});

/**
 * Container for content sections within a layout.<br>
 * @param children<br>
 * @param square          Optional. Whether or not to have rounded corners<br>
 * @param width           Optional. as string with units<br>
 * @param margin          Optional. Shorthand margin as string with units<br>
 * @param padding         Optional. Shorthand padding as string with units<br>
 * @param backgroundColor Optional. background-color as string with hex value, include #<br>
 * @param color           Optional. color as string with hex value, include #<br>
 * @param elevation       Optional. paper elevation casting a shadow as number<br>
 */
function OoPaper({ children, square, width, margin, padding, backgroundColor, color, elevation }) {
  const classes = useStyles({
    width, margin, padding, backgroundColor, color, elevation,
  });

  if (square === true) {
    return (
      <Paper className={classes.root} elevation={elevation} square>
        {children}
      </Paper>
    );
  }
  return (
    <Paper className={classes.root} elevation={elevation}>
      {children}
    </Paper>
  );
}

OoPaper.defaultProps = {
  square: false,
  width: "100%",
  margin: "0 auto",
  padding: "1rem 0.5rem",
  backgroundColor: "initial",
  color: oberionTheme.palette.text.primary,
  elevation: 5,
};
OoPaper.propTypes = {
  children: PropTypes.node.isRequired,
  square: PropTypes.bool,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  elevation: PropTypes.number,
};

export default OoPaper;