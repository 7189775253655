import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import RssFeed from "@material-ui/icons/RssFeed";
import MailOutline from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import OoPaper from "../../element/page-element/OoPaper";
import routes from "../routes";
import { oo } from "../../element/theme";

const callToAction = "Subscribe to Oberion's newsletter and receive invites for exclusive early access to new features";

const CenterText = styled.div`
  text-align: center;
`;

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    width: "10rem",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));


/**
 * Newsletter call to action banner.
 * @param path        Optional. The routing page of the calling component i.e. '/about'.<br>
 * @param message     Optional. The message to display.<br>
 * @param padding     Optional. The shorthand padding as string: default: "3rem 0".<br>
 * @param extraButton Optional. Signals whether to include an extra button.<br>
 */
const NewsletterBanner = ({ path, message, padding, extraButton }) => {
  const concatenatedPath = `${path}#newsletter`;
  const classes = useStyles();
  let RssButton = null;

  if (extraButton) {
    RssButton = (
      <Button href={routes.rss.to}
              variant="outlined" color="secondary" size="large" className={classes.button}>
        RSS Feed
        <RssFeed className={classes.rightIcon} />
      </Button>
    );
  }

  return (
    <OoPaper square backgroundColor={oo.oberionBluePaper} color={oo.textColorOnDark}
             padding={padding}>
      <CenterText>
        <Typography component="h3" variant="h6" gutterBottom paragraph>{message}</Typography>
        <Button component={GatsbyLink} to={concatenatedPath}
                variant="outlined" color="secondary" size="large" className={classes.button}>
          Newsletter
          <MailOutline className={classes.rightIcon} />
        </Button>
        {RssButton}
      </CenterText>
    </OoPaper>
  );
};

NewsletterBanner.defaultProps = {
  path: "",
  message: callToAction,
  padding: "3rem 0.5rem",
  extraButton: false,
};
NewsletterBanner.propTypes = {
  path: PropTypes.string,
  message: PropTypes.string,
  padding: PropTypes.string,
  extraButton: PropTypes.bool,
};

export default NewsletterBanner;